<template>
  <div class="container">
    <nav-bar title="专家系统评判结果"></nav-bar>
    <!-- <level-one :params="params" v-if="params.h > 50 || (params.h > 24 && params.isParty)"></level-one>
    <level-two :params="params" v-else-if="params.h > 24 && params.h <= 50"></level-two>
    <level-three :params="params" v-else></level-three> -->
   <div class="c-box">
     <judge-result :params="params" :result="result"></judge-result>
     <div style="height: 100px;"></div>
   </div>
    <md-action-bar :actions="[{ text: '保存', onClick: this.handleSave }]" v-if="true"></md-action-bar>
  </div>
</template>

<script>
import JudgeResult from '@/components/BuildJudgeResult/Index.vue'
import { result, save } from '@/api/judge'

export default {
  components: { JudgeResult },
  data() {
    return {
      params: {},
      result: {},
      showSave: false,
      isPro: this.$store.getters['user/isPro'],
    }
  },

  created() {
    const isView = this.$route.query.is_view === 'true'
    this.showSave = this.isPro && !isView
    this.params = JSON.parse(this.$ls.get('judge'))
    if (!this.params) {
      this.$dialog.alert({
        title: '警告',
        icon: 'info',
        content: '生成评判结果失败，请重新提交',
        onConfirm: () => this.$router.push({ name: 'judge' }),
      })
    } else {
      // 办公建筑，是否为党政

      const params = {
        h: this.params.h,
        c: this.params.c,
        s: this.params.s,
        s1: this.params.s1,
        v: this.params.v,
        type_id: this.params.typeId,
        level: this.params.level,
        fire_level: this.params.fireLevel,
        spray_level: this.params.sprayLevel,
      }
      if (this.params.isOffice) {
        params['is_party'] = this.params.isParty
      }
      if (this.params.isEntertainment) {
        params['has_1500'] = this.params.hasEtmt1500
      }
      if (this.params.isAncient) {
        params['country_level'] = this.params.ancientLevel === 'country'
      }
      if (this.params.seatNum) {
        params['seat_num'] = Number(this.params.seatNum)
      }

      if (
        (this.params.level === 1 &&
          ((this.params.isHotel && this.params.hasAir) ||
            this.params.isFinancial ||
            this.params.isReserch ||
            this.params.isBook ||
            this.params.isArchive)) ||
        (this.params.level < 3 && (this.params.isShop || this.params.isComplex || this.params.isExhibit))
      ) {
        params['time'] = 3
      } else {
        params['time'] = 2
      }

      result(params).then(res => (this.result = res.data))
    }
  },
  methods: {
    async handleSave() {
      await save({
        body: this.params,
        // user_id: this.$store.getters['user/info'].id,
        user_id: 51,
        // client_id: this.$store.getters['user/info'].invite_code.client.id,
        client_id: "2",
      })
      this.$toast.info('保存成功')
    },
  },
}
</script>

<style lang="stylus" scoped>
.container
>>>.p-text
  font-size 28px
  line-height 48px
  span
    font-weight bold
    color #333
.md-cell-item-body
  padding-top 0
  padding-bottom 0
  min-height 50px
.md-cell-item-title
  font-weight bold

.c-box
  height calc(100vh - 100px)
  overflow-y scroll
</style>
